import React from "react"
import { Box, Text, Flex, Heading, Image } from "@chakra-ui/react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Feature2Image from "@/assets/images/userresearch/steps/image-1.png"
import { headerHeight } from "@/utils/userresearch/constant"

const features = [
  { image: '' },
  { image: Feature2Image },
]

const Steps = () => {
  const { t } = useTranslation()

  return (
    <Box
      as="section"
      id="Steps"
      sx={{ scrollMarginTop: headerHeight }}
      width="full"
      position="relative"
      backgroundColor="#FAFAFA"
    >
     <Heading size="2xl" textAlign="center" fontSize="32px" fontWeight="600" color="#003686" padding="30px" >
          {t(`home-page.steps.heading`)}
      </Heading>

      <Box mx="auto" maxW="5xl">
        <Flex
          px="6"
          py="20"
          columns={{ base: 1, md: 2 }}
          spacing="16"
          textAlign="center"
          pt="-20px"
          direction={{ base: "column-reverse", md: "row" }}
          pb={{ base: "0px" }}
        >
          {features.map(({ image }, index) => (
            <Flex
              direction="column"
              key={`feature-${index + 1}`}
              alignItems="start"
              px="4"
              height="120%"
              marginTop="35px"
              marginLeft={{ base: "0px", sm: "72px" }}

            >
            {image && <Image
                src={image}
                alt={ image && `${t(
                  `home-page.steps.features.${index}.name`
                )} logo`}
                width={{ base: "320px", sm: "320px" }}
                height={{ base: "215px", sm: "215px" }}
              /> }

             { !image &&  <Text  lineHeight="38px"  fontStyle="normal" fontFamily="Noto Sans" fontSize="24px" fontWeight="bold" color="rgba(15, 15, 15, 0.97);">
                {t(
                  `home-page.steps.features.${index}.name`
                )}
              </Text> }

             {!image && <Text  lineHeight="26px" fontStyle="normal" fontFamily="Noto Sans" fontSize="16px" fontWeight="400" color=" rgba(15, 15, 15, 0.8)" pt="20px" textAlign="initial">
                {t(
                  `home-page.steps.features.${index}.description`
                )}
              </Text> }
            </Flex>
          ))}
        </Flex>
      </Box>
    </Box>
  )
}

export default Steps
